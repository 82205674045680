import React, { useState, CSSProperties, useMemo } from "react";

export interface DataRow {
  [key: string]: string | number | boolean;
}

type PaginatedTableProps = {
  theme: "light" | "dark";
  data: DataRow[] | [];
};

const PaginatedTable: React.FC<PaginatedTableProps> = ({ theme, data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [activeFilterColumn, setActiveFilterColumn] = useState<string | null>(
    null
  );

  // Memoized computation for filtered and sorted data
  const processedData = useMemo(() => {
    let filteredData = data;

    // Apply filters
    if (Object.keys(filters).length > 0) {
      filteredData = filteredData.filter((row) =>
        Object.entries(filters).every(([key, value]) => {
          if (!value) return true; // Ignore empty filters
          const cellValue = row[key];
          return cellValue
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        })
      );
    }

    // Apply sorting
    if (sortColumn) {
      filteredData = [...filteredData].sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (aValue == null || bValue == null) return 0;

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
        } else {
          const aStr = aValue.toString();
          const bStr = bValue.toString();
          return sortDirection === "asc"
            ? aStr.localeCompare(bStr)
            : bStr.localeCompare(aStr);
        }
      });
    }

    return filteredData;
  }, [data, filters, sortColumn, sortDirection]);

  const totalPages = Math.ceil(processedData.length / rowsPerPage);
  const displayedData = processedData.slice(
    currentPage * rowsPerPage,
    (currentPage + 1) * rowsPerPage
  );

  const handlePrevious = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(0);
  };

  const handleSort = (columnKey: string) => {
    if (sortColumn === columnKey) {
      // Toggle sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // New column selected, default to ascending order
      setSortColumn(columnKey);
      setSortDirection("asc");
    }
    setCurrentPage(0); // Reset to first page when sorting changes
  };

  const handleFilterIconClick = (key: string) => {
    setActiveFilterColumn((prev) => (prev === key ? null : key));
  };

  const handleFilterChange = (key: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
    setCurrentPage(0); // Reset to first page when filters change
  };

  const cellFormatter = (value: string | number | boolean, key: string) => {
    if (key === "weekendingdate") {
      return value.toString().substring(0, 10);
    }
    if (
      key === "recent_year" ||
      key === "previous_year" ||
      key === "id" ||
      key.toLowerCase() === "year" ||
      key === "month"
    ) {
      return value;
    }
    if (
      key === "growth_contribution_percentage" ||
      key === "revenue_share_percentage" ||
      key === "revenue_growth_percentage" ||
      key === "average_depth_of_discount"
    ) {
      return `${parseFloat(value as string).toFixed(2)}%`;
    }
    if (key === "promo_intensity") {
      return (parseFloat(value as string) * 100).toFixed(2) + "%";
    }
    if (key === "avg_depth_of_discount") {
      return parseFloat(value as string).toFixed(2) + "%";
    }

    if (
      key === "growth" ||
      key === "total_stores_start" ||
      key === "total_volume" ||
      key === "promo_volume" ||
      key === "total_invoice_value" ||
      key === "Total Quantity" ||
      key === "Total Invoice Value" ||
      key === "totalactualinvoicevalue" ||
      key === "totalsimulatedinvoicevalue" ||
      key === "savingspotential"
    ) {
      // make it a whole number and format it to have commas
      return `${parseFloat(value as string)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    if (key === "volume_uplift_percentage" || key === "cagr") {
      return `${parseFloat(value as string).toFixed(2)}%`;
    }

    if (key === "avg_volume_per_product" || key === "avg_weeks_on_promotion") {
      return `${parseFloat(value as string).toFixed(2)}`;
    }
    if (
      key === "volume_driven_revenue_growth" ||
      key === "revenue_growth" ||
      key === "total_revenue_all_channels" ||
      key === "total_revenue" ||
      key === "previous_year_revenue" ||
      key === "revenue_per_store"
    ) {
      // make it a whole number and format it to have commas
      return `$${parseFloat(value as string)
        .toFixed(0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    if (typeof value === "number") {
      if (
        key === "price_driven_growth" ||
        key === "volume_driven_growth" ||
        key === "mix_driven_growth" ||
        key === "recent_revenue" ||
        key === "previous_revenue" ||
        key === "total_revenue_2021" ||
        key === "total_revenue_2022" ||
        key === "volume_driven_revenue_growth" ||
        key === "revenue_growth" ||
        key === "total_revenue_all_channels" ||
        key === "total_revenue" ||
        key === "total_growth"
      ) {
        // make it a whole number and format it to have commas
        return `$${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      if (
        key === "total_baseline_volume" ||
        key === "total_incremental_volume" ||
        key === "incremental_volume" ||
        key === "price_driven_growth" ||
        key === "baseline_volume" ||
        key === "baseline_revenue" ||
        key === "incremental_revenue" ||
        key === "total_optimal_volume" ||
        key === "total_optimal_incremental_volume" ||
        key === "total_previous_volume" ||
        key === "total_previous_incremental_volume" ||
        key === "volume_difference" ||
        key === "incremental_volume_difference" ||
        key === "incremental_volume_difference_percentage" ||
        key === "price_driven_growth" ||
        key === "avg_promo_weeks_per_sku" ||
        key === "avg_incremental_volume_per_promo_week" ||
        key === "avg_baseline_volume_per_sku"
      ) {
        // make it a whole number and format it to have commas
        return `${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      if (
        key === "revenue_growth_percentage" ||
        key === "percentage_growth" ||
        key === "growth_contribution_percentage" ||
        key === "revenue_share_percentage" ||
        key === "avg_depth_of_discount" ||
        key === "promo_pressure" ||
        key === "uplift_percentage" ||
        key === "growth_percentage" ||
        key === "optimal_uplift_percentage" ||
        key === "previous_uplift_percentage"
      ) {
        return `${value.toFixed(2)}%`;
      }
      if (key === "promo_intensity" || key === "incrementality_ratio") {
        return (value * 100).toFixed(2) + "%";
      }
      if (
        key === "avg_price_per_ounce" ||
        key === "avg_promo_price_per_ounce" ||
        key === "avg_non_promo_price_per_ounce" ||
        key === "avg_price" ||
        key === "avg_non_promo_price" ||
        key === "avg_promo_price"
      ) {
        return `$${value.toFixed(4)}`;
      }
      return value.toFixed(2);
    }
    return value;
  };

  const commonCellStyle: CSSProperties = {
    textAlign: "left",
    border: theme === 'dark' ? "1px solid #191919" : "1px solid #DDDDDD",
    padding: "5px",
    position: "relative",
  };

  const headerCellStyle: CSSProperties = {
    ...commonCellStyle,
    backgroundColor: theme === 'dark' ? "#191919" : "#F0F0F0",
    color: theme === 'dark' ? "#FFFFFF" : "#000000",
  };

  const trStyles: CSSProperties = { 
    border: theme === 'dark' ? "1px solid #191919" : "1px solid #DDDDDD",
   };


  const getCellStyle = (value: number | string | boolean): CSSProperties => ({
    ...commonCellStyle,
    textAlign: typeof value === "number" ? "right" : "left",
  });

  // Simple filter icon (you can replace this with an actual icon)
  const FilterIcon = () => (
    <span style={{ marginLeft: "5px", cursor: "pointer" }}>🔍</span>
  );

  // Function to handle CSV export
  const handleExportCSV = () => {
    // Convert processedData to CSV format
    const headers = Object.keys(data[0]);
    const csvRows = [
      headers.join(","), // Header row
      ...processedData.map((row) =>
        headers
          .map((fieldName) => {
            const cellValue = row[fieldName];
            if (cellValue == null) {
              return "";
            }
            // Escape double quotes by doubling them, and wrap in double quotes if needed
            const formattedValue = cellValue.toString().replace(/"/g, '""');
            if (formattedValue.includes(",") || formattedValue.includes('"')) {
              return `"${formattedValue}"`;
            }
            return formattedValue;
          })
          .join(",")
      ),
    ].join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvRows], { type: "text/csv;charset=utf-8;" });

    // Create a link to trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", "table_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ padding: "9px", minWidth: 0 }}>
      <div style={{ overflowX: "auto", width: "100%", minWidth: 0 }}>
        <table style={{ borderCollapse: "collapse" }}>
          <thead>
            <tr>
              {data.length > 0 &&
                Object.keys(data[0]).map((key) => (
                  <th
                    key={key}
                    style={{
                      ...headerCellStyle,
                      textAlign: "center",
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        onClick={() => handleSort(key)}
                        style={{ flexGrow: 1 }}
                      >
                        {key}
                        {sortColumn === key &&
                          (sortDirection === "asc" ? " ▲" : " ▼")}
                      </span>
                    </div>
                    {activeFilterColumn === key && (
                      <input
                        type="text"
                        value={filters[key] || ""}
                        onChange={(e) =>
                          handleFilterChange(key, e.target.value)
                        }
                        style={{
                          width: "100%",
                          marginTop: "5px",
                        }}
                      />
                    )}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {displayedData.map((row, rowIndex) => (
              <tr key={rowIndex} style={trStyles}>
                {Object.entries(row).map(([key, value], colIndex) => (
                  <td
                    key={colIndex}
                    style={{ ...getCellStyle(value), whiteSpace: "nowrap" }}
                  >
                    {cellFormatter(value, key)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
      <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {totalPages > 1 && (
          <div>
            <button onClick={handlePrevious} disabled={currentPage === 0}>
              Previous
            </button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage + 1} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages - 1}
            >
              Next
            </button>
            <label style={{ marginLeft: "10px" }}>
              Rows per page:
              <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                {[5, 10, 15, 20].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </label>
          </div>
        )}
      </div>
      <button
        onClick={handleExportCSV}
        style={{
          margin: "10px 0px",
          borderRadius: "20px",
          padding: "5px 20px",
          cursor: "pointer",
          color: "white",
          border: `1px solid #3F7D7E`,
          backgroundColor: "#3F7D7E",
        }}
      >
        Download CSV
      </button>
    </div>
  );
  
};

export default PaginatedTable;
